import { setContext } from "@apollo/client/link/context";
import { getSessionToken, getSessionApiUrl, getTurnstileToken } from "/imports/api/accounts/client/user-session";

export const getHeaders = () => {
  // get the authentication token from local storage if it exists
  const token = getSessionToken();
  const apiServer = getSessionApiUrl();
  const turnstileToken = getTurnstileToken();

  // return the headers to the context so httpLink can read them
  return {
    authorization: token ? `Bearer ${token}` : "",
    TM_apiServer: apiServer,
    turnstileToken
  };
};

const authLink = setContext((_, { headers }) =>

  // return the headers to the context so httpLink can read them
  ({
    headers: {
      ...headers,
      ...getHeaders()
    }
  }));

export default authLink;
